<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppTitle from "@/Components/Shared/title/AppTitle.vue";
import AppList from "@/Components/Shared/list/AppList.vue";
import AppImage from "@/Components/Shared/image/AppImage.vue";
import AppText from "@/Components/Shared/text/AppText.vue";
import AppReprintBadge from "@/Components/Shared/badge/AppReprintBadge.vue";
import AppListItem from "@/Components/Shared/list/AppListItem.vue";
import { useAttrs } from "vue";

type Props = {
    card: {
        image: string;
        name: string;
        path: string;
    };
    showInfo?: boolean;
    clickable?: boolean;
};
const { card, showInfo = false, clickable = false } = defineProps<Props>();

const attrs = useAttrs();
const classes: AntlerClasses<Props> = {
    base: "flex gap-4 text-left items-center p-2 rounded bg-white border-2 border-white",
};
const { aClass } = installAntlerComponent("card-row", {}, classes, attrs);
</script>

<template>
    <component
        :is="clickable ? 'button' : 'div'"
        :type="clickable ? 'button' : null"
        :class="
            aClass({
                'hover:border-primary': clickable,
                'cursor-pointer': clickable,
            })
        "
    >
        <figure class="h-[64px] w-[64px] shrink-0">
            <AppImage
                :src="card.image"
                placeholder
            />
        </figure>

        <div class="flex flex-col items-start">
            <AppTitle
                as="h4"
                size="xxsmall"
            >
                {{ card.name }}
            </AppTitle>

            <template v-if="showInfo">
                <AppText
                    v-if="card.set"
                    size="small"
                >
                    {{ card.set.name }}
                </AppText>

                <AppList
                    v-if="card.mtg_card"
                    variant="inline"
                    class="text-xs text-slate-400 mt-1"
                >
                    <AppListItem v-if="card.mtg_card.number">
                        #{{ card.mtg_card.number }}
                    </AppListItem>
                    <AppListItem v-if="card.mtg_card.language">
                        {{ card.mtg_card.language }}
                    </AppListItem>
                    <AppListItem v-if="card.mtg_card.frame_effects">
                        {{ card.mtg_card.frame_effects }}
                    </AppListItem>
                    <AppListItem v-if="card.mtg_card.reprint">
                        <AppReprintBadge />
                    </AppListItem>
                </AppList>
            </template>
        </div>
    </component>
</template>
